@keyframes slideIn {
   0% {
      opacity: 0;
      transform: translateY(100%);
   }
   100% {
      opacity: 1;
      transform: translateY(0);
   }
}

.container {
   opacity: 0;
   /*transition: opacity 1s cubic-bezier(0.42, 0, 0.58, 1);*/
   animation: slideIn 0.5s ease-in-out forwards;
   background: var(--secondary-bg-color);
}

.visible {
   opacity: 1;
}

._ {
   width: calc(100% - 20px);
   height: calc(100% - 20px);
   top: 0;
   left: 0;
   z-index: 3;
   overflow: auto;
   padding: 10px;
   display: flex;
   gap: 10px;
   flex-direction: column;
}
.block1 {
   background: var(--color-white-bg);
   border-radius: 10px;
}
.image {
   border-radius: 10px;
   width: 100%;
}
.name {
   font-weight: 600;
   font-size: 20px;
   color: var(--text-color);
   padding: 10px 10px 0 10px;
   width: 70%;
   line-height: 27px;
   text-align: left;
   width: 90%;
}

.block1_2 {
   display: flex;
   justify-content: right;
   gap: 10px;
   padding: 0px 10px 10px 10px;
}
.like {
   transition: opacity 0.3s;
   position: absolute;
   top: 10px;
   right: 15px;
   cursor: pointer;
}
.infoBlock {
   position: relative;
}
.block1_5 {
   display: flex;
   gap: 10px;
   flex-direction: row;
   justify-content: space-evenly;
   margin-top: 16px;
}
.block1_5_1 {
   display: flex;
   flex-direction: column;
   border-radius: 10px;
   padding: 20px 10px;
   background: var(--color-white);
   width: 30%;
   justify-content: center;
}
.block3 {
   background: var(--color-white);
   border-radius: 10px;
   padding: 15px 10px;
   margin-top: 8px;
}
.infoText3 {
   color: var(--color-black);
   display: flex;
   justify-content: space-between;
   gap: 4px;
   font-size: 15px;
   font-weight: 400;
   line-height: 24.6px;
   letter-spacing: -0.02em;
   white-space: nowrap;
   padding-top: 8px;
}
.infoText4 {
   font-weight: 400;
   font-size: 14px;
   text-align: justify;
   color: var(--color-black);
   opacity: 0.9;
   padding-top: 16px;
}
.infoText5 {
   color: var(--color-black);
   font-size: 12px;
   margin-top: 6px;
   font-weight: 300;
   text-align: center;
   font-family: system-ui;
}
.infoText6 {
   color: var(--color-black);
   font-size: 12px;
   font-weight: 600;
   text-align: center;
}

.border {
   border-bottom: 1px solid #e0d9d9;
   width: 100%;
   margin-bottom: 4px;
}

.name3 {
   color: #11141b;
   font-size: 21px;
   font-weight: 700;
   line-height: 27.3px;
   letter-spacing: -0.02em;
}

.block4 {
   background: #f1f1f1;
   border-radius: 16px;
   padding: 20px;
   width: calc(100% - 40px);
   margin-top: 26px;
}

.text4 {
   font-size: 14px;
   font-weight: 300;
   line-height: 16.5px;
   letter-spacing: -0.02em;
   margin-top: 12px;
   color: #000;
}

.payMethod {
   display: flex;
}

.payBlock {
   margin-left: 6px;
}
