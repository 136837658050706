.wrapper {
   width: 100%;
   background: var(--secondary-bg-color);
}

.filterWrapper {
   display: flex;
   overflow-x: scroll;
   gap: 7px;
   margin-top: 22px;
   scrollbar-width: none;
   padding: 8px 0;
}

.itemsWrapper {
   margin-top: 22px;
}

.filterItem {
   font-size: 14px;
   color: #fff;
   fill: #fff;
   transition: all 0.3s ease;
   background: #5f7385;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 52px;
   border-radius: 28px;
   padding: 0 19.5px;
   text-wrap: nowrap;
   font-weight: 400;
   div {
      text-align: center;
      display: inline-block;
      padding: 0 4px;
      white-space: nowrap;
   }
   svg {
      margin-left: 8px;
   }
   &:hover {
      cursor: pointer;
      box-shadow: 1px 2px 11px gray;
   }
}

.activeFilter {
   color: #000;
   fill: #000;
   /* font-weight: 700; */
   /* text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000; */
   background: #beff8b;
}
