._ {
   position: relative;
}
.select {
   font-size: 13px;
   border-radius: 30px;
   padding: 1px 20px;
   background: var(--color-white);
   color: var(--color-black);
   display: flex;
   flex-direction: column;
   justify-content: center;
   height: 48px;
   cursor: pointer;
   transition: all 0.3s ease;
   position: relative;
   z-index: 0;
}
.selectOpen {
   border-radius: 26px 26px 0 0;
   background: var(--color-white);
   color: var(--color-black);
   z-index: 2;
}

.options {
   cursor: pointer;
   font-size: 15px;
   font-weight: 600;
   height: 35px;
   padding-left: 15px;
   padding-right: 9px;
   display: flex;
   align-items: center;
   background: var(--color-white);
   color: var(--color-black);
   /* border-top: 1px solid var(--secondary-bg-color); */
   transition: all 0.3s ease;
}
.options:last-of-type {
   border-radius: 0 0 26px 26px;
}
.selectBlock {
   width: 100%;
   margin-left: -1px;
   background: var(--color-white);
   border: 1px solid var(--secondary-bg-color);
   border-top: none;
   position: absolute;
   border-radius: 0 0 26px 26px;
   z-index: 3;
   max-height: 280px;
   overflow: auto;
   font-weight: 600;
   padding-bottom: 10px;
}
.radio {
   width: 15px;
   height: 15px;
   margin-right: 10px;
   transition: all 0.4s ease;
   background: #fff;
   border-radius: 4px;
   border: 1px solid #000;
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;
}
.radioActive {
   background: #beff8b;
}
.acceptImg {
   width: 11px;
   opacity: 0;
   transition: all 0.4s ease;
}

.acceptImgActive {
   opacity: 1;
}

.fon {
   position: fixed;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
   z-index: 1;
}
.str {
   min-width: 14px;
   min-height: 8px;
   margin-top: 4px;
}
.strRotate {
   transition: all 0.3s ease;
   transform: rotate(180deg);
}
.icon {
   height: 20px;
}
.iconBlock {
   font-weight: 400;
   font-size: 14px;
   display: flex;
   gap: 5px;
   color: var(--color-black);
}

.title {
   display: flex;
   justify-content: space-between;
}
