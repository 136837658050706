.wrapper {
   padding: 30px 15px;
   /* color: #000; */
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background: var(--bg_color);
   border-radius: 19px;
   min-height: 330px;
 }
 
 .wrapperReferal {
   padding: 20px 5px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background: var(--bg_color);
   border-radius: 19px;
   min-height: 330px;
 }
 
 .natification {
   padding: 10px 20px;
   font-size: 14px;
   border-radius: 8px;
   position: fixed;
   bottom: 20px;
   right: 20px;
 }
 
 .subText {
   color: #5f7385;
   font-size: 16px;
   font-weight: 400;
   line-height: 19.2px;
   letter-spacing: -0.02em;
 }
 
 .title {
   font-size: 26px;
   font-weight: 700;
   line-height: 30.8px;
   letter-spacing: -0.02em;
 }
 
 .subTitle {
   font-size: 18px;
   font-weight: 600;
   line-height: 19.8px;
   letter-spacing: -0.02em;
 }
 
 .text {
   font-size: 14px;
   font-weight: 600;
   line-height: 15.4px;
   letter-spacing: -0.02em;
 }
 
 .priceText {
   font-size: 32px;
   font-weight: 700;
   line-height: 35.2px;
   letter-spacing: -0.02em;
 }
 
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }
 