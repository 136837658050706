@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,100..900;1,100..900&display=swap');

:root {
   --main-font: 'Open Sans', sans-serif;
   --secondary-bg-color: var(--tg-theme-secondary-bg-color, #f1f1f1);
   --bg_color: var(--tg-theme-bg-color, #fff);
   --black-text-color: var(--tg-theme-text-color, #11141b);
   --gray-text-color: var(--tg-theme-hint-color, #5f7385);

   --button-bg-color: var(--tg-theme-button-color, #fff);
   --button-text-color: var(--tg-theme-button-text-color, #000);
   --color-grey: #5f7385;
   --color-white: #fff;
   --color-black: #000;
   --color-green: #beff8b;
   --color-white-bg: #f1f1f1;
   /*  OLD  */
   /* --text-green: var(--tg-theme-text-color, #beff8b); */
   /* --dark-gray: var(--tg-theme-hint-color, #23323f); */
   /* --black-text-color: var(--tg-theme-button-text-color, #11141b); */

   /* --link-color: var(--tg-theme-link-color, #007aff);
  --button-color: var(--tg-theme-button-color, #007aff);
  --secondary-bg-color: var(--tg-theme-secondary-bg-color, #efeff3); */
}

* {
   scrollbar-width: none;
}

.App {
   font-family: var(--main-font);
}

.wrapper {
   max-width: 92%;
   margin: 0 auto;
}

a {
   text-decoration: none;
}
