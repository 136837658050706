.wrapper {
   display: flex;
   justify-content: space-between;
   padding: 10px 0;
   font-size: 14px;
   font-weight: 400;
   line-height: 16.8px;
   letter-spacing: -0.02em;
   align-items: center;
   span {
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: -0.02em;
      color: #5f7385;
   }
}

.contact {
   margin-left: 10px;
}

.name {
   font-size: 13px;
   font-weight: 400;
   line-height: 16.8px;
   letter-spacing: -0.02em;
   color: var(--black-text-color);
}

.nick {
   font-size: 10px;
   font-weight: 400;
   line-height: 12px;
   letter-spacing: -0.02em;
   color: var(--gray-text-color);
   margin-top: 4px;
}

.userInfo {
   display: flex;
   align-items: center;
   max-width: 45%;
   width: 45%;
   min-width: 45%;
   font-family: system-ui;
}

.nameText {
   color: #11141b;
}

.darkText {
   color: #fff;
}
.image {
   display: flex;
   justify-content: center;
   align-items: center;
   letter-spacing: -1px;
   font-size: 18px;
   border-radius: 50%;
   height: 55px;
   width: 55px;
   font-weight: 600;
}
