@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-50px);
  }
}

@keyframes backgroundFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.3;
  }
}

@keyframes backgroundFadeOut {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 0;
  }
}

.wrapper {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 3;
  left: 0;
  width: 100%;
}

.content {
  background: #fff;
  z-index: 3;
  width: 100%;
  border-radius: 20px;
  position: relative;
  animation: fadeIn 0.5s ease forwards;
}

.fadeOut {
  animation: fadeOut 0.5s ease forwards;
}

.closeBG {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  background: #000;
  opacity: 0.3;
  animation: backgroundFadeIn 0.5s ease forwards;
}

.closeBG.fadeOut {
  animation: backgroundFadeOut 0.5s ease forwards;
}

.crossImg {
  position: absolute;
  top: 25px;
  right: 20px;
  cursor: pointer;
}
