._{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 10px 0;
}
.buttonGroup {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.buttonGreen{
    font-size: 16px;
    font-weight: 600;
    border-radius: 30px;
    height: 40px;
    padding: 0 15px;
    background: var(--color-green);
    color: var(--color-black);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    transition: all .3s ease;
    position: relative;
    z-index: 0;
    width: 50%;
}
.buttonGrey{
    font-size: 16px;
    font-weight: 600;
    border-radius: 30px;
    height: 40px;
    padding: 0 15px;
    background: var(--color-grey);
    color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    transition: all .3s ease;
    position: relative;
    z-index: 0;
    width: 50%;
}
.buttonSelect{
    background: var(--color-grey);
    color: var(--color-white);
}
.popbuttonGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.popbutton {
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    height: 40px;
    background: var(--button-color);
    color: var(--button-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    transition: all .3s ease;
    position: relative;
    z-index: 0;
    width: 100%;
}
.popText {
    color: var(--text-color);
    text-align: center;
}
.popWindow {
    z-index: 15;
    display: flex;
    flex-direction: column;
    background: var(--bg_color);
    padding: 10px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    opacity: 0.9;
    gap: 10px;
    width: 85%;
    border: solid;
    border-radius: 5px;
    border-color: var(--button-color);
}
.blur {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    backdrop-filter: blur(8px);
    justify-content: center;
    align-items: center;
    display: flex;
}