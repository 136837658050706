.wrapper {
   border-radius: 30px;
   display: flex;
   position: relative;
   padding: 7px 8px;
   align-items: center;
   cursor: pointer;
   transition: all 0.3s ease;
   max-width: calc(92% - 16px);
   background: var(--bg_color);
   margin: 10px auto 0 auto;
 }
 .wrapper:hover {
   box-shadow: 1px 2px 20px gray;
 }
 
 .arrowImg {
   position: absolute;
   right: 20px;
   top: 19px;
   opacity: 0.6;
   transform: rotate(45deg);
 }
 
 .imgWrapper {
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #beff8b;
   height: 40px;
   width: 40px;
 }
 
 .title {
   font-size: 14px;
   font-weight: 600;
   line-height: 16.8px;
   letter-spacing: -0.02em;
   color: #000;
   margin-left: 10px;
 }
 