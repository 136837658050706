.profileWrapper {
  background: var(--bg_color);
}

.nick {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.02em;
  color: var(--gray-text-color);
  margin-top: 4px;
}

.initialPhoto {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -1px;
  font-size: 18px;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  font-weight: 600;
}
.natification {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 8px;
  position: fixed;
  bottom: 20px;
  right: 20px;
}
.reflink {
  border: 1px solid #5f7385;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  width: 80%;
  transition: all 0.3s ease;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.02em;
    color: #5f7385;
  }
  &:hover {
    box-shadow: 1px 2px 15px gray;
    cursor: pointer;
  }
}