._ {
   font-size: 14px;
   font-weight: 400;
   border-radius: 30px;
   height: 48px;
   padding: 0 15px;
   background: var(--color-white);
   color: var(--color-black);
   display: flex;
   align-items: center;
   justify-content: space-between;
   transition: all 0.3s ease;
   position: relative;
   z-index: 0;
}
.input {
   font-size: 12px;
   font-weight: 400;
   border: none;
   width: 51px;
   background: var(--color-white);
   color: var(--color-black);
   margin: 2px 0 0 2px;
   padding: 0 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

.inputWrapper {
   display: flex;
   align-items: center;
   justify-content: start;
   min-width: 50%;
}

.inputTo {
   display: flex;
}
.inputFrom {
   display: flex;
   font-family: system-ui;
}

.nameInput {
   white-space: nowrap;
   font-size: 13px;
}

.input:focus {
   outline: none !important;
   border-color: none;
}
