.wrapper {
   align-items: center;
   display: flex;
   height: 100vh;
   justify-content: center;
   position: fixed;
   top: 0;
   z-index: 3;
   left: 0;
   width: 100%;
}

.content {
   background: #fff;
   z-index: 3;
   width: 100%;
   border-radius: 20px;
   position: relative;
}

.closeBG {
   opacity: 30%;
   position: absolute;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   z-index: 2;
   background: #000;
}

.crossImg {
   position: absolute;
   top: 25px;
   right: 20px;
   cursor: pointer;
}
