.wrapper {
   background: var(--secondary-bg-color);
}

.sotkaImg {
   width: 100%;
   margin-top: 20px;
   border-radius: 18px;
}

.infoWrapper {
   display: flex;
   gap: 10px;
   justify-content: space-between;
   color: #000;
   letter-spacing: -0.02em;
   line-height: 18px;
   margin-top: 15px;
}

.infoItem {
   border-radius: 16px;
   background: #fff;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   min-width: 31%;
   padding: 16px 0;
   font-family: system-ui;
}

.infoTitle {
   font-size: 14px;
   font-weight: 700;
}

.infoValue {
   font-size: 15px;
   font-weight: 400;
   margin-top: 10px;
}

.descrWrapper {
   background: #fff;
   border-radius: 16px 16px 0 0;
   padding: 30px 12px;
   margin-top: 18px;
}

.title {
   color: #11141b;
   font-size: 16px;
   font-weight: 700;
   line-height: 27.3px;
   letter-spacing: -0.02em;
   padding: 0;
}

.mainTitle {
   color: #11141b;
   font-size: 21px;
   font-weight: 700;
   line-height: 27.3px;
   letter-spacing: -0.02em;
   padding: 0;
}

.text {
   font-size: 15px;
   font-weight: 400;
   line-height: 24.6px;
   letter-spacing: -0.02em;
   margin: 20px 0 30px 0;
}

.warningInfo {
   background: #293846;
   border-radius: 16px;
   padding: 20px;
   width: calc(100% - 40px);
   color: #fff;
   font-size: 13px;
   font-weight: 400;
   line-height: 22.96px;
   letter-spacing: -0.02em;
   margin-bottom: 22px;
}

.imgInfo {
   margin: 7px 10px 0 0;
   width: 22px;
}

.greenInfo {
   background: #beff8b;
   border-radius: 16px;
   padding: 20px;
   width: calc(100% - 40px);
   color: #23323f;
   font-size: 13px;
   font-weight: 600;
   line-height: 22.96px;
   letter-spacing: -0.02em;
}

.linkSotka {
   color: #00b9cd;
}

.disText {
   display: flex;
   align-items: start;
}

.imgItems {
   width: 100%;
   margin-bottom: 12px;
   border-radius: 18px;
}
