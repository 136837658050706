._{
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
select::-ms-expand {
    display: none;
}