._ {
   border-radius: 6px;
   width: calc(50% - 5px);
   position: relative;
   cursor: pointer;
}

.info {
   gap: 7px;
   padding: 6px 15px 15px 15px;
   display: flex;
   flex-direction: column;
   align-items: center;
   border-radius: 0 0 15px 15px;
   background: #fff;
}
.block {
   display: flex;
   justify-content: space-between;
}
.block_low {
   display: flex;
   justify-content: space-between;
   flex-direction: column;
   font-size: 13px;
   font-weight: 600;
   line-height: 15.6px;
   letter-spacing: -0.02em;
}
.price {
   font-weight: 600;
   font-size: 18px;
   color: var(--text-color);
   margin: 0;
   font-family: system-ui;
}
.area {
   font-weight: 400;
   font-size: 12px;
   color: var(--text-color);
   margin: 0;
}
.type {
   font-weight: 400;
   font-size: 12px;
   color: var(--text-color);
   margin: 0;
}
.loc {
   font-weight: 400;
   font-size: 11px;
   color: var(--text-color);
   margin: 0;
   min-height: 32px;
   display: block;
   overflow: hidden;
}
.image {
   width: 100%;
   border-radius: 6px 6px 0 0;
}

.wrapperText {
   display: flex;
   align-items: center;
}

.rooms {
   margin-left: 10px;
   font-weight: 400;
   font-size: 13px;
   font-weight: 400;
   line-height: 15.6px;
   letter-spacing: -0.02em;
   text-wrap: nowrap;
}

.imageHouse {
   border-radius: 14px 14px 0 0;
   margin-bottom: -4px;
   width: 100%;
   min-height: 137px;
   max-height: 137px;
}

.heartIcon {
   position: absolute;
   top: 10px;
   right: 10px;
}
