.wrapper {
  width: 100%;
  background: var(--secondary-bg-color);
  min-height: 100vh;
  padding-bottom: 30px;
  margin-top: 24px;
}

.headerImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.headerTextWrapper {
  color: #000;
  text-align: center;
  letter-spacing: -0.02em;
  font-weight: 700;
  background: #fff;
  padding: 20px 20px 40px 20px;
  border-radius: 18px;
  z-index: 1;
}

.headerDescr {
  font-size: 16px;
  line-height: 20.8px;
  margin: 14px 0;
  transition: all 0.3s ease;
}

.headerInfo {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: #5f7385;
  cursor: pointer;
}

.ellipse {
  position: absolute;
  top: -30%;
  width: 60%;
}

.botImg {
  z-index: 1;
}
